import React from "react"
import { graphql } from "gatsby"

import { useEffect } from 'react';

// import '../components/add_captions_to_insta_video.css'
// import '../components/converter.css'

import Layout from "../components/layout";

import SEO from "../components/seo";


import {Link} from "gatsby"

import color20 from "../images/20_color.jpg";
import bwimage from "../images/42.jpg";
// import c1 from '../images/fuchun.jpg';
// import c2 from '../images/girlwithpearl.jpg';
// import c3 from '../images/la_muse.jpg';
// import c5 from '../images/mathura.jpg';
// import c6 from '../images/monalisa.jpg';
// import c7 from '../images/monet.jpg';
// import c8 from '../images/rain_princess.jpg';
// import c9 from '../images/scream.jpg';
// import c10 from '../images/starrynight.jpg';
// import c11 from '../images/sunday.jpg';
// import c12 from '../images/sunflower.jpg';
// import c13 from '../images/udnie.jpg';
// import c14 from '../images/wave.jpg';
// import c15 from '../images/wreck.jpg';
// import c16 from '../images/zhangdaqian.jpg';


// import c17 from '../images/beach.jpg';
// import c18 from '../images/bricks.jpg';
// import c19 from '../images/chicago.jpg';
// import c20 from '../images/clouds.jpg';
// import c21 from '../images/diana.jpg';
// import c22 from '../images/golden_gate.jpg';
// import c23 from '../images/red_circles.jpg';
// import c24 from '../images/seaport.jpg';
// import c25 from '../images/sketch.jpg';
// import c26 from '../images/stata.jpg';
// import c27 from '../images/statue_of_liberty.jpg';
// import c28 from '../images/stripes.jpg';
// import c29 from '../images/towers.jpg';
// import c30 from '../images/udnie.jpg';
// import c31 from '../images/zigzag.jpg';

// import Navblog from '../components/navblog'
// import {setup, uploadImg, predictImg, onPredictClick, updateInputImg, updateStyleImg} from '../components/cartoonized';
// import {main_} from '../components/main';
import {color_my_image} from '../components/upload_n_color';
import {converter_, images_to_pdf, mergeAllPDFs} from '../components/converter';

import {view,compress_, download_compressed_file} from '../components/compress';

// import {} from '../components/image_black_white';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';

// require('@tensorflow/tfjs-backend-cpu');
// require('@tensorflow/tfjs-backend-webgl');
// const cocoSsd = require('@tensorflow-models/coco-ssd');

// import $ from jquery;
// import CancelIcon from '@mui/icons-material/Cancel';
// import Sortable from 'sortablejs';

// import SearchPhotos from "../components/searchPhotos";
import GetDescription from "../components/searchPhotos";

// require('@tensorflow/tfjs-backend-cpu');
// require('@tensorflow/tfjs-backend-webgl');
// const cocoSsd = require('@tensorflow-models/coco-ssd');
// import { Dropzone } from "react-dropzone";
// let model;
// (async () => {
// const model = await cocoSsd.load();
// })();


// var dt = new DataTransfer();
var dt = [];

function deletePDF(e) {

  e.stopPropagation();
  e.preventDefault();

  console.log('target delete pdf', e.target, e.target.parentNode);

  // it could be the first occurance of e.target, need to check
  var index = Array.prototype.indexOf.call(e.target.parentNode.parentNode.children, e.target.parentNode);
  console.log('index', index)
  
  // const { files } = fileInp
  
  // for (let i = 0; i < files.length; i++) {
  //   const file = files[i]
  //   // if (index !== i)
  // dt.items.remove(index) // here you exclude the file. thus removing it.
  dt = dt.splice(index, 1);
  e.target.parentNode.remove();



}


// const addExternalScript = (url, callback) => {
//   const script = document.createElement('script');
//   script.src = url;
//   script.async=true;
//   // script.onload = callback;
//   document.body.appendChild(script);
// };

// function getBase64Image(img) {
//   var canvas = document.getElementById("myCan");
//   canvas.width = img.width;
//   canvas.height = img.height;
//   var ctx = canvas.getContext("2d");
//   ctx.drawImage(img, 0, 0);
//   var dataURL = canvas.toDataURL("image/png");
//   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
// }

export default function BlogPost({ data }) {
// export default BlogPost( props ) {

  const post = data.markdownRemark

  // console.log('data...2', data);

//  compress file
useEffect(() => {
  

  

  try {


    // addExternalScript("https://cdn.jsdelivr.net/npm/@tensorflow/tfjs")

    // addExternalScript("https://cdn.jsdelivr.net/npm/@tensorflow-models/coco-ssd")
//   document.getElementById('cirP').style.display = 'none';
  
// if (post.frontmatter.author == "cartoonized") {
//   setup();
// }

// if (post.frontmatter.author == "cartoon") {
//   main_();
// }

// if (post.frontmatter.author == "object") {
//   (async () => {
//     model = await cocoSsd.load();
//   })
// }



document.getElementById('imgPath').onchange = () => {
  color_my_image();
}

const imgP = document.getElementById("imgPath_");
const imgP2 = document.getElementById("imgPath_2");
const img = document.getElementById("imagex_");
const canvas = document.getElementById("canvas_bw");
const ctx = canvas.getContext("2d");

imgP.onchange = function () {

  const _file = document.getElementById('imgPath_').files[0];
  // document.getElementById('show_loader').style.display = 'block'
  document.getElementById("imagex_").src = URL.createObjectURL(_file) 

}

imgP2.onchange = function () {

  const _file = document.getElementById('imgPath_2').files[0];
  // document.getElementById('show_loader').style.display = 'block'
  document.getElementById("imagex_").src = URL.createObjectURL(_file) 

}


img.onload = function () {
  // body...

img.crossOrigin = "anonymous";
// ctx.filter = "grayscale(100%)";

ctx.canvas.height = img.naturalHeight
ctx.canvas.width = img.naturalWidth
ctx.filter = "grayscale(100%)";
// var img = document.getElementById("dataimage");
ctx.drawImage(img,0,0, canvas.width, canvas.height);

// ctx.putImageData(img, 0, 0);

var dt = canvas.toDataURL('image/jpeg');
var a = document.getElementById('download')
a.href = dt;
a.setAttribute("download", "blackandwhite.jpeg")



}





  document.getElementById("demo").addEventListener("change", () => {
      view(post.frontmatter.drop);
      // mergeAllPDFs();
  });



//   document.getElementById("convertB").addEventListener("click", 
//   () => {
//     console.log('merge the pdfs pls');
  
//     mergeAllPDFs();
// });

  document.getElementById("okcompress").addEventListener("click", 
  () => {
    compress_(post.frontmatter.drop);
});

  // download_compressed_file();
     
} catch(e) {
console.log(' no compress ');
}

},);



  useEffect(() => {


    try 
{
var drop = document.getElementById("dropzone");
var tostop = ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"];
for (var n in tostop) {
    drop.addEventListener(tostop[n], function(e) {
        e.preventDefault();
        e.stopPropagation();
        e.originalEvent = e;
    });
}
tostop = ["dragover", "dragenter"];
for (var i in tostop) {
    drop.addEventListener(tostop[i], function(e) {
        document.getElementById("dropzone").classList.add("bg-light");
    });
}
tostop = ["dragleave", "dragend", "drop"];
for (var k in tostop) {
    drop.addEventListener(tostop[k], function(e) {
        document.getElementById("dropzone").classList.remove("bg-light");
    });
}

document.getElementById("images").addEventListener("change", function(e) {
  console.log('in images on Change');
  e.stopPropagation();
  e.preventDefault();


  if (post.frontmatter.author == "images_pdf") {
    var output = document.getElementById('file-chosen');
    var children = "";
    for (var i = 0; i < document.getElementById("images").files.length; ++i) {
        children += document.getElementById("images").files.item(i).name + ',';
    }
    // output.innerHTML = '<ul>'+children+'</ul>';
    output.innerHTML = children;
  
    // converter_();
    
    images_to_pdf();
    return;
}

  document.getElementById('filediv').style.display = 'none';
  // 
  var fileInp = document.getElementById('images');

 

  // const input = fileInp;
  const { files } = fileInp
  
  for (let i = 0; i < files.length; i++) {
    const file = files[i]

    // debugger;
    // if (index !== i)
      // dt.items.add(file) // here you exclude the file. thus removing it.
      dt.push(file);
  }
  
  // input.files = dt.files



  // var children = "";
  var src;
  var gpa, pa, e1, e2, e3;

  gpa = document.getElementById('pdfView_files');
  for (var i = 0; i < fileInp.files.length; ++i) {
      
      src= URL.createObjectURL(fileInp.files[i]);

      pa = document.createElement("div");
      pa.id = 'displayfile' + i;
      pa.setAttribute('class', 'displayfile');

      e1 = document.createElement("div");
      e1.setAttribute('class', 'close');
      e1.onclick = deletePDF;
      pa.appendChild(e1);

      e2 = document.createElement("object");
      e2.setAttribute('class', 'pdfView');
      e2.setAttribute('type', 'application/pdf');
      e2.setAttribute('data', src);
      pa.appendChild(e2);

      e3 = document.createElement("div");
      e3.setAttribute('class', 'filename_display');
      e3.innerHTML = fileInp.files[i].name;
      pa.appendChild(e3);

      gpa.appendChild(pa);
  }



  // output.innerHTML = '<ul>'+children+'</ul>';
  // output.innerHTML = children;

  // converter_();
 

  // mergeAllPDFs();
})



// <div id="displayfile0" className="displayfile">
// <div className="close"></div>
// <object className="pdfView" type="application/pdf" data="blob:http://localhost:8000/1cc61d74-6cde-4b75-a7a3-1bab196eba66"></object>
// <div className="filename_display">216925_2020721_2511789.pdf</div>
// </div>

// <div id="displayfile1" className="displayfile">
// <div className="close"></div>
// <object className="pdfView" type="application/pdf" data="blob:http://localhost:8000/1cc61d74-6cde-4b75-a7a3-1bab196eba66"></object>
// <div className="filename_display">2511789.pdf</div>
// </div>


drop.addEventListener("drop", function(e) {

  console.log('in on drop');
  e.stopPropagation();
  e.preventDefault();


    // console.log(e);

   

    if (post.frontmatter.author == "images_pdf") {
      document.getElementById("images").files = e.originalEvent.dataTransfer.files;
      var output = document.getElementById('file-chosen');
      var children = "";
      for (var i = 0; i < document.getElementById("images").files.length; ++i) {
          children += document.getElementById("images").files.item(i).name + ',';
      }
      // output.innerHTML = '<ul>'+children+'</ul>';
      output.innerHTML = children;
    
      // converter_();
      images_to_pdf();

      return;
  }

    document.getElementById('filediv').style.display = 'none';
    document.getElementById("images").files = e.originalEvent.dataTransfer.files;



    // var output = document.getElementById('file-chosen');
    var fileInp = document.getElementById('images');


    const { files } = fileInp
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // debugger;
      // if (index !== i)
        // dt.items.add(file) // here you exclude the file. thus removing it.
      dt.push(file);
      }


    // var children = "";
    var src;
    var gpa, pa, e1, e2, e3;

    gpa = document.getElementById('pdfView_files');
    for (var i = 0; i < fileInp.files.length; ++i) {
        
        src= URL.createObjectURL(fileInp.files[i]);

        pa = document.createElement("div");
        pa.id = 'displayfile' + i;
        pa.setAttribute('class', 'displayfile');

        e1 = document.createElement("div");
        e1.setAttribute('class', 'close');
        e1.onclick = deletePDF;
        pa.appendChild(e1);

        e2 = document.createElement("object");
        e2.setAttribute('class', 'pdfView');
        e2.setAttribute('type', 'application/pdf');
        e2.setAttribute('data', src);
        pa.appendChild(e2);

        e3 = document.createElement("div");
        e3.innerHTML = fileInp.files[i].name;
        e3.setAttribute('class', 'filename_display');
        pa.appendChild(e3);

        gpa.appendChild(pa);
    }
    // output.innerHTML = '<ul>'+children+'</ul>';
    // output.innerHTML = children;

    // converter_();
   
    // mergeAllPDFs();

});

} catch(e) {

}


  },);
// render() {

  // console.log('data...2', data);
  // console.log('data...3', post.frontmatter);

  const siteTitle = data.site.siteMetadata.title
  const { ogimage } = post.frontmatter
  const ogImagePath = ogimage && ogimage.childImageSharp.fixed.src

      // <small>{post.frontmatter.date}</small>


      let x 

      if ( post.frontmatter.author == "textprompt") {
        x = <div>
          <div className="container">
          
            {/* <SearchPhotos /> */}
            <GetDescription />
          </div>

        </div>
      }

 
      if (post.frontmatter.author == "images_pdf") {
    
        x = 
        <div>
        <Box id='cirP2'>
          <CircularProgress className='cirP2' color="inherit" />
        </Box>

        <div className='drop2' id='drop2'>Drop more files below to convert</div>
        <div class="jumbotron" id="dropzone">
          <div id='filediv'>
            <span>Drop files here, or</span>
            <br/>
            <input type="file" className="file-upload-wrapper" accept="image/*" id="images" type="file" name="pwd" multiple hidden />
            <label for="images">
              <CloudUploadIcon className='mui_cloudU'/>
            <span className='okoriginal1'>Choose File</span>
            </label>
            <div id="file-chosen"></div>
        
            {/* <button type="button" id="convertB" >Convert</button> */}
          </div>
          <div id='displayfile'></div>
        </div>
        </div>
        
      }

      if (post.frontmatter.author == "merge pdf") {
    
        x = 
        <div className='mergePdf'>

        <div className='drop2' id='drop2'>Drop more files below to convert</div>

        <button class='how1' type="button" id="convertB" onClick={() => mergeAllPDFs(dt)}>{post.frontmatter.button_text}</button>

       

        <div class="jumbotron" id="dropzone">

    
          <div id='filediv'>
            <span>{post.frontmatter.drop}</span>
            <br/>
            <input type="file" className="file-upload-wrapper" accept="application/pdf" id="images" type="file" name="pwd" multiple hidden />
            <label for="images">
              <CloudUploadIcon className='mui_cloudU'/>
            <span className='okoriginal1'>{post.frontmatter.choose}</span>
            </label>
            <div id="file-chosen"></div>


            
          </div>


          

          <div id='pdfView_files'>

          


          </div>

          

        </div>
        </div>
        
      } 
      

      if ( post.frontmatter.author == "gagacolor2") {

        x = <div className="arrange_photos">
        <label className="label_upload">
        <div className="hero-cta w-inline-block">
          <div className="w-embed"></div>
          <div className="button-text-block link">Choose File</div>
          <input type="file" name="pic" accept="image/*" id="imgPath" style={{"display": "none"}} />
        
          <div className="arrow-bubble">
            <img src="https://assets-global.website-files.com/616e938268c8f02f94b2b53c/616e938268c8f03e4db2b839_Upload.svg" loading="eager" alt="Choose Subtitle File" />
          </div>
        </div>  
        </label>
        
        <div className="text-center"> Less than 5MB, 1200×1200, Support .jpg .jpeg .png.</div>
        
        
        <div id="trial_photos">
          <img src={bwimage} id="imagex" alt="your photo" width="40%" />
        
          <div id='set_loader'>
              <img id="outx" />
              <div className="loader" id='show_loader'></div>
          </div>
        </div>
        </div>

      }


      if ( post.frontmatter.author == "gagacolor") {
        x = <div className="arrange_photos">

        <label className="label_upload">
        <div className="hero-cta w-inline-block">
          <div className="w-embed"></div>
          <div className="button-text-block link">Upload Image</div>
          <input type="file" name="pic" accept="image/*" id="imgPath_" style={{"display": "none"}} />
      
        <div className="arrow-bubble">
          <img src="https://assets-global.website-files.com/616e938268c8f02f94b2b53c/616e938268c8f03e4db2b839_Upload.svg" loading="eager" alt="Choose Subtitle File" />
        </div>
        </div>  
        </label>
      
        <div id="trial_photos_bw" >
          <div className="check_back">
            <img src={color20} id="imagex_" alt="your photo"  />
          </div>
          <div className="check_back">
            <canvas className="canvas_bw_2" id="canvas_bw"></canvas>
          </div>
        </div>
      
      
        <div id='center-link'>
      
         <div className="upload">
          <label for="imgPath_2">
          <div className="text-center" style={{"cursor":"pointer"}}>Upload Color Image</div>
          <input type="file" name="pic" accept="image/*" id="imgPath_2" style={{"display": "none"}} />
          </label>
         </div>
         <a id="download" download className="text-center">Download bw Image</a>
        </div>
      
      
      </div>
      
        
      }

//        {/* <script src={withPrefix('script.js')} type="text/javascript" />
     

  return (
    <div class="pagestyles">

      <SEO 
          author={post.frontmatter.author}
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          type="blog"
          image={ogImagePath}
          canonical={post.frontmatter.canonical}
      />
  
  


    <div className='flex_head'>
    <Link className='homeicon' to='/'><HomeIcon /></Link>

    <h1 className="text-center trial_head h1_bw" style={{"margin-left": "8vw", "margin-right": "8vw"}}>{post.frontmatter.heading}</h1>
    </div>  
    <h4 className="text-center" style={{"line-height": "2vw"}}>{post.frontmatter.summary}</h4>


{x}



      <div dangerouslySetInnerHTML={{ __html: post.html }} />

      <footer className='foot-blog'>
        © {new Date().getFullYear()}
        {` `}
      <Link to="/">Gagacolor</Link>
      </footer>
      

    </div>
  )
}



export const query = graphql`
  query BlogQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } } ) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        summary
        heading
        author
        canonical
        drop
        choose
        button_text
        description
    ogimage { 
      childImageSharp {
        fixed {
          src
        }
      }
    }

      }
    }
  }
`