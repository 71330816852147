import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import defaultOpenGraphImage from '../images/icon.png'

function SEO({ author, canonical, description, lang, meta, keywords, title, type, image }) {

  return (
    <StaticQuery
      // query={detailsQuery}
      render={data => {
        console.log('data...', data);
        const metaDescription =
          description || data.site.siteMetadata.description
        const ogImageUrl = data.site.siteMetadata.siteUrl + ( image || defaultOpenGraphImage )
        const ogType = type;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            link={
              canonical
                ? [{ rel: 'canonical', key: canonical, href: canonical }]
                : []
            }
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `image`,
                content: ogImageUrl,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: ogType,
              },
              {
                property: `og:image`,
                content: ogImageUrl,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: ogImageUrl,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  button_text: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  canonical: PropTypes.string,
  drop: PropTypes.string,
  choose: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

// const detailsQuery = graphql`
//   query DefaultSEOQuery {
//     site {
//       siteMetadata {
//         title
//         description
//         siteUrl
//       }
//     }
//   }
// `