/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"



// import "./converter.css";

// import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {


  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
       allMdx{
            nodes {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    title
                }
            }
        }
    }
  `)




  return (
    <>
 
      <div>



        <main>{children}</main>

        
        <footer className='foot'>
          © {new Date().getFullYear()}
          {` `}
        <Link to="/">Gagacolor</Link>
        </footer>
        <div className='footerx'>

        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


                    // date
                    // path
                    // canonical
                    // drop
                    // choose
                    // button_text
                    // description