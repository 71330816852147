
import './searchphotos.css';


import React, { useState } from "react";
import { createApi, toJson } from "unsplash-js";


// var Unsplash = require('unsplash-js').default;

const unsplash = createApi({
  accessKey: "ilxhFAbC5jc4znooGs8XXQgZ6k6qOi-7PoMDAs9nP1I",
});

export function SearchPhotos() {
  const [query, setQuery] = useState("");
  const [pics, setPics] = useState([]);
  console.log(query);

  const searchPhotos = async (e) => {
    e.preventDefault();
    console.log("Submitting the Form")

    unsplash.search
    .getPhotos(
      {query: query })
    .then(toJson)
    .then((json) => {
      console.log(json);
      // var n = json.response.length ;/
      // if n > 0
      setPics(json.response.results);
      // console.log(json.response.results[0])
    });

  };


  return (
    <>
      <form className="form" onSubmit={searchPhotos}>
      <label className="label" htmlFor="query">
          {" "}
          📷
        </label>
        <input
          type="text"
          name="query"
          className="input"
          placeholder={`Try "dog" or "apple"`}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button type="submit" className="button">
          Search
        </button>
      </form>
      <div className="card-list">
      {
          pics.map((pic) => <div className="card" key={pic.id}>
             <img
                className="card--image"
                alt={pic.alt_description}
                src={pic.urls.full}
                width="50%"
                height="50%"
              ></img>
          </div>
          )
        }
      </div>
    </>
  );
}


export default function GetDescription() {
  const [query, setQuery] = useState("");
  const [pics, setPics] = useState([]);
  console.log(query);

  let x = Math.floor((Math.random() * 10) + 1);
  console.log('apge no.', x);

  const searchPhotos = async (e) => {
    e.preventDefault();
    console.log("Submitting the Form")

    unsplash.photos
    .getRandom(
      {query: query,
        count: 10
      })
    .then(toJson)
    .then((json) => {
      console.log(json);
      var n = json.response.length ;
      // if n > 0
      // setPics(json.response.results);
      setPics(json.response);
      console.log(json.response)
    });

  };


  return (
    <>
      <form className="form" onSubmit={searchPhotos}>
      <label className="label" htmlFor="query">
        </label>
        <input
          type="text"
          name="query"
          className="input"
          placeholder={`Try "tattoo" or "vikings tattoo"`}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button type="submit" className="button">
          Search
        </button>
      </form>
      <div className="card-list">
      {
          pics.map((pic) => <div className="card" key={pic.id}>
             <div
                className="card--image"
                width="50%"
                height="50%"
              >{pic.alt_description}</div>
          </div>
          )
        }
      </div>
    </>
  );
}