
// import React, { useEffect } from 'react';

import {compress, compressAccurately, downloadFile} from 'image-conversion';
import './compress.css';
import { saveAs } from 'file-saver';


let file;
let download_blob;
export function compress_(drop) {

    if (!file) {
        return;
    }

    let sizeT = Number(document.getElementById("xsize").value) || 80;

    let typeF = file.type;
    if(typeF == "image/png") {
        typeF = "image/jpg";
    }

    if(drop == 'jpeg') {
        typeF = "image/jpeg";
    }
    const config = {size: sizeT, type: typeF};

    document.getElementById('cirP').style.display ='inline';
     
    compressAccurately(file,config).then(res=>{
      //The res in the promise is a compressed Blob type (which can be treated as a File type) file;
      console.log(res);

      const compressedImage = document.querySelector("#compressedImage");  
      compressedImage.src = URL.createObjectURL(res);
      document.querySelector("#size").innerHTML = bytesToSize(res.size);

      document.getElementById('bothImages').style.display = 'flex';
      
      download_blob = res;
      document.getElementById('cirP').style.display ='none';
    })
}

export function view(drop){

    file = document.getElementById('demo').files[0];



    // document.getElementById('file-chosen').textContent = file.name;

    const originalImage = document.querySelector("#originalImage");
    originalImage.src = URL.createObjectURL(file);

    document.querySelector("#Osize").innerHTML = bytesToSize(file.size);

    let sizeT = Number(document.getElementById("xsize").value) || 80;

    console.log('config.', config);
    let typeF = file.type;
    if(typeF == "image/png") {
        typeF = "image/jpg";
    }
    const config = {size: sizeT, type: typeF};

    document.getElementById('cirP').style.display ='inline';
     
    compressAccurately(file,config).then(res=>{
      //The res in the promise is a compressed Blob type (which can be treated as a File type) file;
      
     
      console.log(res);

    

      const compressedImage = document.querySelector("#compressedImage");  
      compressedImage.src = URL.createObjectURL(res);      
      document.querySelector("#size").innerHTML = bytesToSize(res.size);

      document.getElementById('bothImages').style.display = 'flex';

      download_blob = res;

      document.getElementById('cirP').style.display ='none';
    });
    
  }

export function download_compressed_file() {

    document.getElementById("okdown").addEventListener("click", () => {

        downloadFile(download_blob, file.name);
    });
      
}

export function addevents() {

    const fileInput = document.querySelector("#upload");

    const originalImage = document.querySelector("#originalImage");

    const resizingElement = document.querySelector("#resizingRange");

    const qualityElement = document.querySelector("#qualityRange");

    let resizingFactor = 0.8;
    let quality = 0.8;

// initializing the compressed image
    compressImage(originalImage, resizingFactor, quality);

    fileInput.addEventListener("change", async (e) => {
        const [file] = fileInput.files;

       
        // storing the original image
        originalImage.src = await fileToDataUri(file);
    
        // compressing the uplodaded image
        originalImage.addEventListener("load", () => {
            compressImage(originalImage, resizingFactor, quality);
        });
    
        return false;
    });
    
    resizingElement.oninput = (e) => {
        resizingFactor = parseInt(e.target.value) / 100;
        compressImage(originalImage, resizingFactor, quality);
    };
    
    qualityElement.oninput = (e) => {
        quality = parseInt(e.target.value) / 100;
        compressImage(originalImage, resizingFactor, quality);
    };

    console.log('events added!');
}    



//     useEffect(() => {


// uploadButton.onclick = () => {
//     // uploading the compressed image to
//     // Imgur (if present)
//     if (compressedImageBlob) {
//         const formdata = new FormData();
//         formdata.append("image", compressedImageBlob);

//         fetch("https://api.imgur.com/3/image/", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 Authorization: "Client-ID YOUR_CLIENT_ID"
//             },
//             body: formdata
//         }).then((response) => {
//             if (response?.status === 403) {
//                 alert("Unvalid Client-ID!");
//             } else if (response?.status === 200) {
//                 // retrieving the URL of the image
//                 // just uploaded to Imgur
//                 response.json().then((jsonResponse) => {
//                     alert(`URL: ${jsonResponse.data?.link}`);
//                 });
//                 alert("Upload completed succesfully!");
//             } else {
//                 console.error(response);
//             }
//         });
//     } else {
//         alert("Rezind and compressed image missing!");
//     }
// };


// }


function compressImage(imgToCompress, resizingFactor, quality) {
    // showing the compressed image
    
    let compressedImageBlob;

    const compressedImage = document.querySelector("#compressedImage");

    const resizingElement = document.querySelector("#resizingRange");
    
    const qualityElement = document.querySelector("#qualityRange");


    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const originalWidth = imgToCompress.width;
    const originalHeight = imgToCompress.height;

    const canvasWidth = originalWidth * resizingFactor;
    const canvasHeight = originalHeight * resizingFactor;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    context.drawImage(
        imgToCompress,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
    );

    let base64 = imgToCompress.src;  
    let file_ext = base64.substring(base64.indexOf('/') + 1, base64.indexOf(';base64'));
    
    // reducing the quality of the image
    canvas.toBlob(
        (blob) => {
            if (blob) {
                compressedImageBlob = blob;
                compressedImage.src = URL.createObjectURL(compressedImageBlob);
                document.querySelector("#size").innerHTML = bytesToSize(blob.size);


            }
        },

        
        file_ext,
        quality
    );
}

function fileToDataUri(field) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            resolve(reader.result);
        });
        reader.readAsDataURL(field);
    });
}

// source: https://stackoverflow.com/a/18650828
function bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    if (bytes === 0) {
        return "0 Byte";
    }

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}