import { saveAs } from 'file-saver';
import {zip, JSZip} from 'jszip';
import './converter.css';
import './compe.css';
import './loader.css';
import './index.css';
import './slider.css';
import './text.css';
import './trial.css';

import { jsPDF } from "jspdf";
import { PDFDocument } from 'pdf-lib';


// A stock photo of suit, jury, speaker, and tie. Small contrasting details in color. In color film.

// a black and white photo of a man sitting at a desk by Albert Paris Gütersloh, reddit, new objectivity, 1920s, matte photo, 1970s

var droppedFiles = false;
// var drop = document.getElementById("dropzone");

// window.convertto = 'png';
// window.zip = null;

var JpgToPngConvertor = function() {
    function convertor(imageFileBlob, options) {
        options = options || {};
        const defaults = {
            downloadLinkSelector: '.js-download-' + window.convertto
        };
        const settings = extend(defaults, options);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext("2d");
        const imageEl = createImage(settings.parentEl, options);
        const downloadLink = settings.downloadEl || createDownloadLink();
        function createImage(parent, options) {
            
            
            const img = (Image) ? new Image() : document.createElement('img');
            // const parent = options.parentEl || document.body;
            console.log('parent .. div1', parent)
            // console.log('options', options);
            // console.log('options.height', options.height);

            img.style.width = (options.width) ? options.width + 'px' : 'auto';
            img.style.height = (options.height) ? options.height + 'px' : 'auto';
            return img;
        }
        function extend(target, source) {
            for (let propName in source) {
                if (source.hasOwnProperty(propName)) {
                    target[propName] = source[propName];
                }
            }
            return target;
        }
        function createDownloadLink() {
            return document.createElement('a');
        }
        function isFirefox() {
            return navigator.userAgent.indexOf("Firefox") > -1;
        }
        function download(blob) {
            // Add download link to DOM in case it is not there and on the firefox
            if (!window.zip) {
                if (!settings.parentEl.contains(downloadLink) && isFirefox()) {
                    downloadLink.style.display = 'none';
                    settings.parentEl.appendChild(downloadLink);
                }
                if ('click' in downloadLink) {
                    downloadLink.click();
                } else {
                    downloadLink.dispatchEvent(createClickEvent());
                }
            } 
            
            // else {
            //     zip.file(document.getElementById("images").files[converted].name.substring(0, document.getElementById("images").files[converted].name.indexOf(".")) + "." + window.convertto, blob);
            //     converted++;
            //     if (converted == document.getElementById("images").files.length) {
            //         zip.generateAsync({
            //             type: "blob"
            //         }).then(function(content) {
            //             saveAs(content, "images.zip");
            //         });
            //     }
            // }
        }
        function updateDownloadLink(jpgFileName, pngBlob) {
            console.log(jpgFileName);
            const linkEl = downloadLink;
            var pngFileName = "";
            if (document.getElementById("exampleFormControlSelect1").value.toLowerCase() == 'jpeg') {
                pngFileName = jpgFileName.replace(/jpe?g/i, window.convertto);
            } else {
                pngFileName = jpgFileName.replace(document.getElementById("exampleFormControlSelect1").value.toLowerCase(), window.convertto);
            }

            console.log('name..', pngFileName, settings.ImageName);
            settings.ImageName.textContent = pngFileName;
            

            linkEl.setAttribute('download', pngFileName);
            linkEl.href = window.URL.createObjectURL(pngBlob);
            console.log('blob',pngBlob);

            var Outimg = document.createElement('img');
            Outimg.src = window.URL.createObjectURL(pngBlob);
            Outimg.setAttribute('class', 'outimages');
            settings.parentEl.appendChild(Outimg);


            
            // If there is custom download link we don't download automatically
            if (settings.downloadEl) {
                settings.downloadEl.style.display = 'block';
            } else {
                // download(pngBlob);
            }
        }
        function createClickEvent() {
            if ('MouseEvent' in window) {
                return new MouseEvent('click');
            } else {
                const evt = document.createEvent("MouseEvents");
                evt.initMouseEvent("click", true, true, window);
                return evt;
            }
        }
        function process() {
            const imageUrl = window.URL.createObjectURL(imageFileBlob);
            imageEl.onload = (e) => {
                canvas.width = e.target.width;
                canvas.height = e.target.height;
                ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
                console.log('image/' + window.convertto);
                
                
                canvas.toBlob(updateDownloadLink.bind(window, imageFileBlob.name), 'image/' + window.convertto, 1);
                

            };
            imageEl.src = imageUrl;
            if (settings.downloadEl) {
                settings.downloadEl.style.display = 'none';
            }

            document.getElementById('drop2').style.display = 'block';
        }
        return {
            process: process
        };
    }
    return convertor;
}

var conv = new JpgToPngConvertor();
var converted = 0;
// window.convert = function() {


async function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });
      }

export async function mergeAllPDFs(dt) {
    // merge in order, option to set order in frontend
    const urls = dt;
    console.log('urls in dt', urls);

    const pdfDoc = await PDFDocument.create();
    const numDocs = urls.length;
    
    for(var i = 0; i < numDocs; i++) {
        // const donorPdfBytes1 = await fetch(urls[i]).then(res => res.arrayBuffer());
        
        const donorPdfBytes =  await convertFileToBase64(urls[i]);
        
        const donorPdfDoc = await PDFDocument.load(donorPdfBytes);
        const docLength = donorPdfDoc.getPageCount();
       

        for(var k = 0; k < docLength; k++) {
            const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
            //console.log("Doc " + i+ ", page " + k);
            pdfDoc.addPage(donorPage);
        }
    }

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    // console.log(pdfDataUri);
  
    // strip off the first part to the first comma "data:image/png;base64,iVBORw0K..."
    var data_pdf = pdfDataUri.substring(pdfDataUri.indexOf(',')+1);
    

      // Construct the <a> element
  var link = document.createElement("a");
  link.download = 'merged.pdf';
  // Construct the uri
//   var uri = 'data:text/csv;charset=utf-8;base64,' + someb64data
  var uri = pdfDataUri;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  // Cleanup the DOM
  document.body.removeChild(link);

}




export async function images_to_pdf() {

    document.getElementById('cirP2').style.display ='inline';

    console.log(' in images to pdf ');

    let pdf = new jsPDF('l', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pageRatio = pageWidth / pageHeight;

    const urls = document.getElementById("images").files;

    console.log('urls', urls);

    for (let i = 0; i < urls.length; i++) {
        let img = new Image();
        img.src = await convertFileToBase64(urls[i]);
        
        // img.onload = function(){
        // alert( img.width+", "+img.height );
        // };



        // debugger;
        // reader.onload = () => {
        //     // img = reader.result;
        // // reader.onerror = reject;
        // img = reader.result;
        img.onload = function () {
            const imgWidth = this.width;
            const imgHeight = this.height;
            const imgRatio = imgWidth / imgHeight;
            if (i > 0) { pdf.addPage(); }
            pdf.setPage(i + 1);
            if (imgRatio >= 1) {
                const wc = imgWidth / pageWidth;
                if (imgRatio >= pageRatio) {
                    pdf.addImage(img, 'JPEG', 0, (pageHeight - imgHeight / wc) / 2, pageWidth, imgHeight / wc, null, 'NONE');
                }
                else {
                    const pi = pageRatio / imgRatio;
                    pdf.addImage(img, 'JPEG', (pageWidth - pageWidth / pi) / 2, 0, pageWidth / pi, (imgHeight / pi) / wc, null, 'NONE');
                }
            }
            else {
                const wc = imgWidth / pageHeight;
                if (1 / imgRatio > pageRatio) {
                    const ip = (1 / imgRatio) / pageRatio;
                    const margin = (pageHeight - ((imgHeight / ip) / wc)) / 4;
                    pdf.addImage(img, 'JPEG', (pageWidth - (imgHeight / ip) / wc) / 2, -(((imgHeight / ip) / wc) + margin), pageHeight / ip, (imgHeight / ip) / wc, null, 'NONE', -90);
                }
                else {

                    pdf.addImage(img, 'JPEG', (pageWidth - imgHeight / wc) / 2, -(imgHeight / wc), pageHeight, imgHeight / wc, null, 'NONE', -90);
                }
            }
            if (i == urls.length - 1) {
                pdf.save('image_to_pdf.pdf');
                document.getElementById('cirP2').style.display ='none';
            }
        }
    }
}



export function converter_() {

    window.convertto = 'png';
    window.zip = null;


    document.getElementById('filediv').style.display = 'none';
    document.getElementById('displayfile').textContent = '';


    converted = 0;
    window.convertto = document.getElementById("exampleFormControlSelect2").value.toLowerCase();
    console.log(window.convertto);


    var options = {};
    options['height'] = '20px';
    options['width'] = '20px';

    var Div;
    var Down;
    var ImgName;
    // if (!document.getElementById("tozip").checked) {
    if (!document.getElementById("tozip")) {
        window.zip = null;
        for (var i = 0; i < document.getElementById("images").files.length; i++) {
            Div = document.createElement('div');
            Div.setAttribute('id', 'div' + i.toString() );
            Div.setAttribute('class', 'div_out');
            document.getElementById('displayfile').appendChild(Div);
            options['parentEl'] = Div;

            Down = document.createElement('a');
            Down.setAttribute('class', 'download_Out');
            Down.textContent = 'Download';
            Div.appendChild(Down);
            options['downloadEl'] = Down;

            ImgName = document.createElement('span');
            ImgName.setAttribute('class', 'imgname');
            Div.appendChild(ImgName);
            options['ImageName'] = ImgName;


            conv(document.getElementById("images").files[i], options).process();
        }
    } else {
        window.zip = new JSZip();
        for (var i = 0; i < document.getElementById("images").files.length; i++) {
            Div = document.createElement('div');
            Div.setAttribute('id', 'div' + i.toString() );
            Div.setAttribute('class', 'div_out');
            document.getElementById('displayfile').appendChild(Div);
            options['parentEl'] = Div;

            Down = document.createElement('a');
            Down.setAttribute('class', 'download_Out');
            Down.textContent = 'Download';
            Div.appendChild(Down);
            options['downloadEl'] = Down;

            ImgName = document.createElement('span');

            conv(document.getElementById("images").files[i], options).process(zip);
        }
    }

    // document.getElementById('filediv').style.display = 'block';
    // document.getElementById('displayfile').textContent = '';

}

