// document.getElementById('imgPath').onchange = () => {

export function color_my_image() {
  console.log('Now uploading...');
  const file = document.getElementById('imgPath').files[0]

  // console.log(file)


  if (file) {
      document.getElementById("imagex").src = URL.createObjectURL(file)

      document.getElementById('show_loader').style.display = 'block'

      getPresignedUrl(file).then((res) => {
        // console.log('signed url', res)
      
        putToS3(file, res).then((res) => {
            console.log('res', res);            
            console.log('file', file);

            color_my_photo(file).then((res) => {

                console.log('colored url', res);

                document.getElementById('show_loader').style.display = 'none'

                document.getElementById("outx").src = "data:image/jpg;base64," + res
                // console.log('color my photo', res)
              }).catch(e => console.log('color my photo error',e));

        }).catch(e => console.log('s3 error',e)) ;
    });
  }

}





         
async function getPresignedUrl(fileObject) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    "https://ahyrbmivd6.execute-api.us-west-2.amazonaws.com/default/generate_pre_signed_url?filename=" +
      fileObject.name +
      "&filetype=" +
      fileObject.type,
    requestOptions
  );


  return await response.json();
}





async function putToS3(fileObject, presignedUrl) {

  var data = new FormData()
  data.append('file', fileObject)

  const response = await fetch(presignedUrl, {
    method: 'PUT',
    body: fileObject,
    headers: { 'Content-Type': 'application/octet-stream' } 
  });

  return await response;
}



async function color_my_photo(file) {


  const api_url = 'https://nqanrivuu9.execute-api.us-west-2.amazonaws.com/default/color_my_image?key=' + file.name

  const response = await fetch(api_url, {
                method: "POST",
                headers: {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }, 
                body: JSON.stringify({a: 1, b: 2})
              })


  return await response.json();
}